import {
  Gender,
  MyProfileFragment,
  Race,
  StateCode,
  YesNoPreferNotRespond,
  YesNoPreferNotRespondNotSure,
} from '~/__generated__/graphql';

export const getCurrentSegmentStates = (profile: MyProfileFragment | null | undefined) => {
  if (profile !== null && !!profile?.collegeAdmissionPlans?.preferredCollegeStates) {
    if (!profile.collegeAdmissionPlans.preferredCollegeStates.includes(StateCode.NotSure)) {
      return profile.collegeAdmissionPlans.preferredCollegeStates;
    }
  }
  return ['No Answer'];
};

const getCurrentSegmentLatinoBackground = (profile: MyProfileFragment | null | undefined) => {
  switch (profile?.demographic?.latinoBackground) {
    case YesNoPreferNotRespond.Yes:
      return 'Yes';
    case YesNoPreferNotRespond.No:
      return 'No';
    case YesNoPreferNotRespond.PreferNotToRespond:
      return 'Prefer Not To Respond';
    default:
      return 'unknown';
  }
};

export const getCurrentRecruitmentChoice = (profile: MyProfileFragment | null | undefined) => {
  switch (profile?.collegeRecruitmentInfo?.collegeRecruitmentInd) {
    case true:
      return 'Yes - Opted-In';
    case false:
      return 'No - Opted-Out';
    default:
      return 'unknown';
  }
};

export const getEducatorConnections = (profile: MyProfileFragment | null | undefined) => {
  if (profile?.encourageUser.educatorConnections?.length) {
    return profile?.encourageUser.educatorConnections?.length > 0 ? 'Yes - Connected' : 'No - Not Connected';
  }
  return 'No - Not Connected';
};

export const getCurrentSegmentEthnicity = (profile: MyProfileFragment | null | undefined) => {
  const ethnicityList = [];

  const hispanicBackground = getCurrentSegmentLatinoBackground(profile);
  if (hispanicBackground === 'Yes') {
    ethnicityList.push('Latino');
  }

  if (profile?.demographic?.race != null) {
    profile.demographic?.race?.forEach(element => {
      switch (element) {
        case Race.AmericanIndianAlaskaNative:
          return 'American Indian or Alaskan Native';
        case Race.Asian:
          return 'Asian';
        case Race.BlackAfricanAmerican:
          return 'Black or African American';
        case Race.NativeHawaiianOtherPacificIslander:
          return 'Native Hawaiian or Other Pacific Islander';
        case Race.White:
          return 'White';
        case Race.PreferNotToRespond:
          return 'Prefer not to answer';
        default:
          return 'unknown';
      }
    });
  }
  return ethnicityList;
};

export const getCurrentSegmentFirstGenerationCollegeStudent = (profile: MyProfileFragment | null | undefined) => {
  switch (profile?.parentGuardianInfo?.parentCollegeCompletionInd) {
    case YesNoPreferNotRespondNotSure.Yes:
      return 'Not First Generation';
    case YesNoPreferNotRespondNotSure.No:
      return 'First Generation';
    case YesNoPreferNotRespondNotSure.NotSure:
      return 'Not sure';
    case YesNoPreferNotRespondNotSure.PreferNotToRespond:
      return 'Prefer Not To Respond';
    default:
      return 'unknown';
  }
};

export const getCurrentSegmentGender = (profile: MyProfileFragment | null | undefined) => {
  switch (profile?.demographic?.gender) {
    case Gender.AnotherGender:
      return 'Another Gender';
    case Gender.Female:
      return 'Female';
    case Gender.Male:
      return 'Male';
    case Gender.PreferNotToRespond:
      return 'Prefer not to respond';
    default:
      return 'unknown';
  }
};

export const getCurrentSegmentUserState = (profile: MyProfileFragment | null | undefined) => {
  return profile?.contact?.homeAddress?.stateCode ?? 'unknown';
};
