import jwtDecode, { JwtPayload } from 'jwt-decode';
import Router from 'next/router';

import { StorageKeys } from '~/constants/localStorage';
import ROUTES from '~/constants/ROUTES';
import localCache from '~/helpers/localCache';

import { asError } from './errors';
import Logger from './logger';
import { sentryRoutingError } from './SentryErrors';

const logger = new Logger('authTokens');

export const setTimeDrift = (token: string | null) => {
  try {
    const decoded = jwtDecode<JwtPayload>(token || '');
    if (decoded.iat) {
      const iatMs = decoded.iat * 1000;
      const timeDrift = Date.now() - iatMs;
      localCache.set(StorageKeys.TIME_DRIFT, timeDrift);
    }
  } catch (_: unknown) {
    localCache.set(StorageKeys.TIME_DRIFT, undefined);
  }
};

export const getTimeDrift = () => localCache.get<number | undefined>(StorageKeys.TIME_DRIFT);

/** Access Token Helpers */
export const setAccessToken = (token: string | null) => {
  setTimeDrift(token);
  localCache.set(StorageKeys.ACCESS_TOKEN, token);
};
export const getAccessToken = () => localCache.get<string | null>(StorageKeys.ACCESS_TOKEN);

/** Refresh Token Helpers */
export const setRefreshToken = (token: string | null) => {
  localCache.set(StorageKeys.REFRESH_TOKEN, token);
};
export const getRefreshToken = () => localCache.get<string | null>(StorageKeys.REFRESH_TOKEN);

/** LTI Token Helpers */
export const setLtiToken = (token: string | null) => {
  localCache.set(StorageKeys.LTI_TOKEN, token);
};
export const getLtiToken = () => localCache.get<string | null>(StorageKeys.LTI_TOKEN);

/** Clear all tokens */
export const clearTokens = (): void => {
  localCache.set(StorageKeys.ACCESS_TOKEN, undefined);
  localCache.set(StorageKeys.LTI_TOKEN, undefined);
  localCache.set(StorageKeys.REFRESH_TOKEN, undefined);
  localCache.set(StorageKeys.TIME_DRIFT, undefined);
};

export const clearTokensAndRedirect = (route: string, params?: Record<string, string | undefined>) => {
  clearTokens();
  // Necessary or we get into redirect loop
  if (![ROUTES.SIGNIN, route].includes(Router.router?.pathname || '')) {
    // eslint-disable-next-line promise/prefer-await-to-then
    Router.router?.push({ pathname: route, query: params }).catch((unkErr: unknown) => {
      sentryRoutingError({ error: unkErr, message: 'Error while redirecting', query: params, to: route });
      logger.error(asError(unkErr));
    });
  }
};
