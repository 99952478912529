import { useQuery } from '@apollo/client';
// eslint-disable-next-line regex/invalid-warn, @typescript-eslint/no-unused-vars
import * as Sentry from '@sentry/nextjs';

import { GetMyProfileQuery, GetMyProfileQueryVariables, LoginGetMyProfileDocument, useGetMyProfileQuery } from '~/__generated__/graphql';
import { FiniteQueryState, useToFiniteStateWithProcessing } from '~/helpers/apollo/finiteQueryState';
import { getAccessToken } from '~/helpers/authTokens';
import useAuthReactiveTokens from '~/hooks/useAuthReactiveTokens';

export interface IMyProfileData {
  completedOnboardingSurvey: boolean;
  isInternational?: boolean;
  isLoggedIn: boolean;
  recruitmentOptIn?: boolean | null;
}

export type IMyProfileResult = Pick<GetMyProfileQuery, 'myProfile'> & IMyProfileData;

export type IMyProfile = FiniteQueryState<IMyProfileResult>;

export const useMyProfile = (): IMyProfile => {
  const reactiveAccessToken = useAuthReactiveTokens(getAccessToken);
  const result = useGetMyProfileQuery({
    onCompleted: data => {
      Sentry?.setUser({
        email: data.myProfile?.contact?.email || undefined,
        id: data.myProfile?.profileId,
      });

      Sentry?.setTags({
        firstName: data.myProfile?.identity?.firstName,
        lastName: data.myProfile?.identity?.lastName,
        visitorId: data.myProfile?.encourageUser?.visitorId,
      });
    },
    // Necessary to access College Profile page while not logged in
    skip: !reactiveAccessToken,
  });

  // This is so the user can log in faster, without querying all the extra stuff
  const minResult = useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(LoginGetMyProfileDocument, {
    // Necessary to access College Profile page while not logged in
    skip: !reactiveAccessToken || !!result?.data?.myProfile,
  });

  const postReadyFunc = (data: Pick<GetMyProfileQuery, 'myProfile'>): IMyProfileData => {
    const completedOnboardingSurvey = !!data?.myProfile?.encourageOnboardingComplete;
    const countryCode = data?.myProfile?.contact?.homeAddress?.countryCode;
    const isInternational = countryCode ? countryCode !== 'US' : undefined;
    const isLoggedIn = !!data?.myProfile?.profileId;
    const recruitmentOptIn = data.myProfile?.collegeRecruitmentInfo?.collegeRecruitmentInd;

    return {
      completedOnboardingSurvey,
      isInternational,
      isLoggedIn,
      recruitmentOptIn,
    };
  };

  return useToFiniteStateWithProcessing(result?.data?.myProfile ? result : minResult, postReadyFunc);
};

export default useMyProfile;
