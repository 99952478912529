import React from 'react';

function useLockedFunction<T extends (...args: unknown[]) => unknown>(
  fn: T,
): (...args: Parameters<T>) => Promise<ReturnType<T> extends Promise<infer U> ? U : ReturnType<T>> {
  const isRunningRef = React.useRef(false);

  return async (...args: Parameters<T>): Promise<ReturnType<T> extends Promise<infer U> ? U : ReturnType<T>> => {
    if (isRunningRef.current) {
      return undefined as ReturnType<T> extends Promise<infer U> ? U : ReturnType<T>;
    }

    isRunningRef.current = true;

    try {
      const result = await fn(...args);
      return result as ReturnType<T> extends Promise<infer U> ? U : ReturnType<T>;
    } finally {
      isRunningRef.current = false;
    }
  };
}
export default useLockedFunction;
